import { Visibility } from "authory-api-types/dist/enums";
import { ArticlePreviewResponse } from "authory-api-types/dist/types";

export const getItemUrl = (item: ArticlePreviewResponse, itemsHref: string, collectionSlug?: string, isPrivateView?: boolean, isMainDomain: boolean = true) => {
    if (isPrivateView) return `${itemsHref}/${item.slug}`;

    if (collectionSlug) {
        return isMainDomain ?
            `${itemsHref}/${item.slug}?c=${collectionSlug}` :
            `/${item.slug}?c=${collectionSlug}`;
    }
    if (item.effectiveVisibility === Visibility.Preview && item.originalUrl) return item.originalUrl;

    return isMainDomain ? `${itemsHref}/${item.slug}` : `/${item.slug}`;
}
