import { ContentResponse } from "authory-api-types/dist/types";
import { assign } from "lodash";
import { QueryClient } from '@tanstack/react-query';
import { ItemResponse } from "../../../api/articles";
import { getItemQuerie } from "../../../types/queries";

export const onArticleOptimisticUpdate = (queryClient: QueryClient, token: string | null, itemSlug: string, newData: ContentResponse) => {
    // Snapshot the previous value
    const oldItem = queryClient.getQueryData<ItemResponse>(getItemQuerie(token, itemSlug));

    if (!oldItem) return oldItem;

    const newArticle = assign({}, oldItem.article, newData);
    const newItem = assign({}, oldItem, { article: newArticle });

    // Optimistically update to the new value
    queryClient.setQueryData(getItemQuerie(token, itemSlug), newItem);

    // Return the snapshotted value
    return oldItem;
}