import styled from "styled-components";
import { media, theme } from "../../theme/theme";

export const CBDWrapper = styled.div`
    padding: 12px 24px;
    align-items: center;
    display: flex;
    border-radius: 6px;
    border: 1px solid rgba(127, 167, 246, 0.3);
    background: ${theme.v3.colors.lightBlue};
    width: 100%;
    flex-direction: column;

    ${media.phone} {
        padding: 12px 32px;
        flex-direction: row;
    }

    svg {
        margin-right: 12px;
    }

    > div {

        &:first-child {
            min-width: 48px;
            min-height: 48px;
        }

        margin-bottom: 12px;

        ${media.phone} {
            margin-bottom: 0;
        }

        &:nth-child(2) {
            width: 100%;
        }

        &:not(:last-child) {
            margin-right: 24px;
        } 
    }
`;