import { NextPageContext } from "next";
import { useRouter } from "next/router";
import { parseCookies } from "nookies";
import toast from 'react-hot-toast';
import React, { useContext, useState } from "react";
import { QueryClient, useMutation, useQuery, dehydrate } from '@tanstack/react-query';
import { getAuthorContentQuerie, getAuthorQuerie, getPublicCertificatesListQuerie } from "../../types/queries";
import { Collection } from "authory-api-types/dist/types/collections";
import { getAuthorServer } from "../../utils/getAuthor";
import { withGlobalPageSpinner } from "../../components/GlobalPageSpinner";
import { ContactUserFormik } from "../../components/V3ContactUser";
import { ContactUser, getUser } from "../../api/user";
import { subscribe } from "../../api/subscribe";
import { APPLICATION_ROUTES } from "../../types/routes";
import { cloneDeep } from "lodash";
import { V3GenericErrorHandler } from "../../api/error-handler";
import { processQueryArgs } from "../../utils/processQueryArgs";
import { buildContentFilterQueryParams } from "../../pagelayouts/content/utils/buildContentFilterQueryParams";
import { getContentInfiniteQuerie } from "../../utils/query-utils/contentInfiniteQuerie";
import { getUserContent } from "../../api/content";
import { DomainContext } from "../../context/domainContext";
import { RequestHost } from "../../types/headers";
import { getPublicCertificates } from "../../api/certificates";
import { UNAVAILABLE_RESOURCE_TYPE } from "../../types/unavailable_resource_type";
import { getAuthenticatedUserClient, getAuthenticatedUserServer } from "../../utils/getAuthenticatedUser";
import { ProfileSearchForm, V4ProfilePublicPageLayout } from "../../pagelayouts/profile/V4ProfilePublicPageLayout";
import { CookieContext } from "../../context/cookieContext";
import { getIsLegacyBio } from "../../utils/getIsLegacyBio";
import { FREE_PLAN_ITEM_LIMIT } from "authory-api-types/dist/types";

export const getDefaultCollection = (collections: Collection[] = [], owner: boolean) => {
    const defaultCollection = collections.filter(coll => coll.starred);
    return defaultCollection?.length && !owner ? defaultCollection[0].slug : undefined;
}

const PortfolioPublicPage = () => {
    const router = useRouter();
    const token = useContext(CookieContext) as string;
    const requestHostHeader: RequestHost = useContext(DomainContext) ?? RequestHost.MAINDOMAIN;
    const [customDomainSearchTriggered, setCustomDomainSearchTriggered] = useState(false);

    const { authenticatedUser } = getAuthenticatedUserClient(token, router, true);
    const authorSlug = router.query.author as string;

    const isAuthenticatedWithInactiveProfile = !!authenticatedUser.data && !authenticatedUser.data.displayAuthoryPage && authenticatedUser.data.slug === authorSlug;

    // Get the author
    const author = useQuery({
        queryKey: getAuthorQuerie(authorSlug),
        queryFn: () => getUser(authorSlug),
        meta: { logNotFound: true }
    });

    const { searchArgs } = processQueryArgs(router.query, author.data);

    // Get the certificates
    const certificates = useQuery({
        queryKey: getPublicCertificatesListQuerie(null, authorSlug),
        queryFn: () => getPublicCertificates(authorSlug, null)
    });

    // Get the articles
    const customResultLimit = author.data?.isActiveFreeAccount ? FREE_PLAN_ITEM_LIMIT : undefined;
    const params = buildContentFilterQueryParams(searchArgs, true, customResultLimit, true);
    const articles = getContentInfiniteQuerie(null, author.data?.slug, params, params.collection !== undefined);

    // Contact User Mutation
    const contactUserMutation = useMutation({
        mutationFn: ContactUser,
        onError: V3GenericErrorHandler(toast.error),
    });

    const onContactUserSubmit = async (data: ContactUserFormik) => {
        if (!author.data) return;

        await contactUserMutation.mutateAsync({ userSlug: author.data?.slug, data });
    }

    const subcribeMutation = useMutation({
        mutationFn: subscribe,
        onError: V3GenericErrorHandler(toast.error),
    });

    const onSubscribeFormSubmit = async (email: string) => {
        if (!author.data) return;
        await subcribeMutation.mutateAsync({ email, userId: author.data?.slug });
    }

    const onSearchFormSubmit = (formikData: ProfileSearchForm) => {
        if (!author.data) return;

        const queryParams = [];
        const data = cloneDeep(formikData);

        for (const [key, value] of Object.entries(data)) {
            //@ts-ignore
            if (data[key] && data[key].length) queryParams.push(`${key}=${value}`);
        }

        if (requestHostHeader === RequestHost.MAINDOMAIN) {
            router.push(`${APPLICATION_ROUTES.AUTHOR_PAGE.replace("[author]", author.data?.slug)}?${queryParams.join("&")}`, undefined, { shallow: true });
        } else {
            setCustomDomainSearchTriggered(true);
            window.location.href = `?${queryParams.join("&")}`;
        }
    }

    const legacyEditingExperience = getIsLegacyBio(author.data?.activeDate);

    return withGlobalPageSpinner(
        author.isLoading,
        <V4ProfilePublicPageLayout
            author={author.data!}
            items={articles.data?.pages || []}
            itemsLoading={articles.isFetching || customDomainSearchTriggered}
            itemsHref={`${APPLICATION_ROUTES.AUTHOR_PAGE.replace("[author]", author.data?.slug!)}`}
            onContactUserSubmit={onContactUserSubmit}
            onSubscribeSumit={onSubscribeFormSubmit}
            contentCanFetchmore={(articles.hasNextPage && !author.data?.isActiveFreeAccount) || false}
            contentLoadmore={articles.fetchNextPage}
            onSearchFormSubmit={onSearchFormSubmit}
            queryArgs={searchArgs as Record<string, string>}
            subscribedModalOpen={router.query.target === "subscribed"}
            unsubscribedModalOpen={router.query.target === "unsubscribed"}
            initialContactOpen={router.query.target === "contact"}
            visitorBannerOpen={router.query.target === "visitor"}
            customDomainSearchTriggered={customDomainSearchTriggered}
            certificates={certificates.data?.certificates}
            subscribeOpen={router.query.target === "subscribe"}
            rules={[]}
            isAuthenticatedWithInactiveProfile={isAuthenticatedWithInactiveProfile}
            legacyEditingExperience={legacyEditingExperience}
        />
    );
}

PortfolioPublicPage.getInitialProps = async (ctx: NextPageContext) => {
    const isServer = !!ctx.req;
    const cookies = parseCookies(ctx);
    const queryClient = new QueryClient();

    if (isServer) {
        try {
            const author = await getAuthorServer(queryClient, ctx);
            if (!author) return {};

            const authenticatedUser = await getAuthenticatedUserServer(queryClient, ctx, true);
            const isOwner = author?.slug.toLowerCase() === authenticatedUser?.slug.toLowerCase();

            // Author exists and it's active, however profile is turned off
            if ((!author.displayAuthoryPage && !isOwner)) {
                return {
                    dehydratedState: dehydrate(queryClient),
                    unavailableResourceType: UNAVAILABLE_RESOURCE_TYPE.PROFILE,
                    statusCode: 403,
                    cookies
                };
            }

            const { searchArgs } = processQueryArgs(ctx.query, author);
            const customResultLimit = author.isActiveFreeAccount ? FREE_PLAN_ITEM_LIMIT : undefined;

            // Get the articles
            const params = buildContentFilterQueryParams(searchArgs, true, customResultLimit, true);

            const articleQuery = getAuthorContentQuerie(
                null,
                author.slug,
                params
            );

            await queryClient.prefetchInfiniteQuery({
                queryKey: articleQuery,
                queryFn: () => getUserContent(null, author.slug, params),
                initialPageParam: 1,
            });

            await queryClient.prefetchQuery({
                queryKey: getPublicCertificatesListQuerie(null, author.slug),
                queryFn: () => getPublicCertificates(author.slug, null)
            });

            return {
                dehydratedState: dehydrate(queryClient),
                statusCode: ctx.res?.statusCode,
            };

        } catch {
            ctx.res!.writeHead(308, { Location: `${process.env.NEXT_PUBLIC_FRONTEND_URL}${APPLICATION_ROUTES.UNAVAILABLE_PROFILE}` });
            ctx.res!.end();
            return;
        }
    } else {
        return {};
    }
}

export default PortfolioPublicPage;
